.mainContainerSignup{
    height: auto;
    display: flex;
    justify-content: center;
    padding: 50px 0px;
    align-items: center;
}


.cardSignup{

    height: auto;
    width: 600px;
    background-color: #fff;
    padding: 20px 0px;
    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 40px rgb(51 51 51 / 10%);


}

.topSignupTxt{

    font-size: 16px;
    font-weight: 600;
    color: #111;
    font-family: 'Poppins', sans-serif;
    
}
.tellAbtTxt{

    font-size: 14px;
    /* font-weight: 600; */
    color: #636e7d!important;
    font-family: 'Poppins', sans-serif;

}

.profileImg{
    border: 1px solid black;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputfile{
    display: none;
}

.chooseImageTxt{
    
    font-size: 14px;
    font-weight: 600;
    color: #111;
    font-family: 'Poppins', sans-serif;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(46, 53, 62, 0.15);

    
}

.topProfilePic{
    display: flex;
    align-items: center;
    gap: 10px;
}


.inputLbl{
    

    /* color: #5a7184; */
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;


}

.inputSignup{

    background: #fff;
    border: 1px solid #c3cad9;
    height: 48px;
    margin-top: 6px;
    padding-left: 8px;
    border-radius: 5px;
    color: #111;
    font-family: 'Poppins', sans-serif;
    width: 325px;
    width: 100%;

}

.btnContinue{

    border: none;
    width: 400px;
    border-radius: 8px;
    height: 60px;
    color: #fff;
    background-color: #0c1a41;

        width: 100%;

}

.cardSignupInner{

    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin: auto; */
}

.topProfInLabl{
    display: flex;
    flex-direction: column;
}


.topProfInLabl{
    font-size: 14px;
    font-weight: 600;
    color: #636e7d!important;
    font-family: 'Poppins', sans-serif;
}

@media (max-width:630px) {

    .cardSignup{
        width: 95%;
    }

    .cardSignupInner{
        width: 95%;
    }

    
}