.verificationMain{
    max-width: 500px;
    margin: auto;
    /* padding-top: 40px; */
}

.codeBtn{
    color: black;
    font-size: 20px;
    padding: 0px 10px;
    border: 2px solid black;
    display: flex;
    cursor: pointer;
    justify-content: center;
    border-radius: 5px;
}

.mainVerifyCode{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.inputSignupCode{
    width: 600px;
}
.verifyTxt{
    font-size: 24px;
    color: black;
    font-weight: 500;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 30px;
}
@media (max-width:650px) {
    .inputSignupCode{
        width: unset;
    }
    
}