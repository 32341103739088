.mainContainerVideo{
    /* background-image: url('./../../images/video.png');
    height: 567px;
    width: 100%;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    z-index: 23; */
    /* margin-top: -160px; */
    position: relative;
    height: 578px;
    z-index: 99;
    /* background-color: red; */
    /* background: linear-gradient(341.68deg, #24262E 24.19%, rgba(23, 23, 23, 0) 79.85%), radial-gradient(33.06% 25.99% at 50% 32.63%, #013657 15.62%, #001B2C 100%) */
}   


.leftYellow{
    height: 400px;
    width: 50%;
    position: absolute;

    /* z-index: -1; */
    background: #FFD733;

    clip-path: polygon(1% 39%, 0 73%, 100% 75%);
    transform: rotate(2deg);
    margin-left: -20px;
    /* margin-top: -113px; */
    display: none;
    top: -113px;

z-index: 9;    /* margin-bottom: -40px; */

}

.backImgVideo{
    width: 100%;
    /* object-fit: cover; */
    position: absolute;
    z-index: 99;
    height: 534px;
}

.topImageTextVid{
    width: 100%;
    height: 110%;
    display: flex;
    padding-top: 60px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 999999;
}
.modal-dialog{
    max-width: unset !important;
    max-width: 70% !important;
    /* height: 100vh !important    ; */
}
.modal-content{
    height: 60vh !important;
    height: 500px !important;
    
}
.watchVidTxt{
    font-weight: 500;
font-size: 50.42px;
line-height: 62px;
/* identical to box height, or 123% */

text-align: center;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 50.42px;
margin-top: 25px;
line-height: 62px;
/* identical to box height, or 123% */

text-align: center;

color: #FFFFFF;

}


.articalIntTxt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21.52px;
    line-height: 225.5%;
    /* or 49px */
    
    text-align: center;
    
    color: #F2F2F2;
max-width: 933px;

/* margin-top: 10px; */
}

.playButton{
    cursor: pointer;
}

.topVideo{
    position: absolute;

}

.modal-body{
    display: flex;
    justify-content: center;
}

.modal-body div{
   width: 100% !important;
   height: 100% !important;
}
.topImageVideo{
    width: 100%;
    /* object-fit: cover; */
    position: absolute;
    z-index: 99;
    height: 534px;
    background: linear-gradient(89.68deg, rgba(116, 185, 43, 0.53) 22.23%, rgba(48, 157, 45, 0.53) 93.04%);
mix-blend-mode: darken;

}
@media (max-width:600px) {
    .playButton{
        width: 100px;
    }

.watchVidTxt{
    font-size: 32px;
}
.articalIntTxt{
    font-size: 18px;
    padding: 0px 20px;
}
.leftYellow{
   
    transform: rotate(-27deg);
   

}
.articalIntTxt{
    line-height: 30px;
}
}