.vehicleAdminMain{
    /* height: 500px; */
    width: 100%;
    /* background-color: red; */
    margin-top: 100px;
    margin-left: 1px;
}

.topSideVehicles{
    display: flex;
    /* gap: 0px; */
}

.topTextsVehiclAdmin{
    display: flex;
    justify-content: center;
    width:700px;
    margin: auto;
    margin-top: 40px;

    gap: 100px;
}

.topTextEditAdmVehic{
    /* font-size: 20px; */
    color: #03BABC !important;
    text-align: center;
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;


}


.btnAddVehicle{
    background-color: #03BABC;
    border-color: #03BABC;
    color: #fff!important;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
}


.inputSearchVehic{
    height: 45px;
    border-radius: 5px;
    width: 300px;
    border: none;
    padding-left: 10px;
    border: 1px solid #03BABC;
    font-family: 'Poppins', sans-serif;


}

.btnSearchVehicle{
    height: 45px;
    border-radius: 5px;
    width: 100px;
    border: none;
    padding-left: 10px;
    box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%);
    color: #03BABC!important;

    border: 1px solid #03BABC;
    font-family: 'Poppins', sans-serif;

}


.topInputBtnSearch{
    display: flex;
    gap: 10px;
}


.topTableAdminVehicles{
    max-width: 1200px;
    margin: auto;
    margin-top: 71px;

    overflow-y: scroll;
    height: 500px;


}

.topAddVehicleDrawer .navbar{
    padding: 0px;


}
.topAddVehicleDrawer .navbar .container-fluid{
    padding: 0px;


}
.deleteVehicleIcon{
    color: rgb(171, 77, 77);
    font-size: 20px;
    cursor: pointer;
}

.mainDeleteCardVehicle{
    width: 280px;
    height: auto;
    padding: 20px;
    background-color: #fff;
    position: absolute;
    top: 40%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    gap: 20px;

    left: 50%;
    filter: drop-shadow(26.69px 26.69px 150px rgba(0,0,0,.15));

    box-shadow: 0 0 0 1000px rgba(0,0,0,.3);
    /* for real browsers */
    
    box-shadow: 0 0 0 100vmax rgba(0,0,0,.3);
    border-radius: 10px;
    z-index: 99999;


}

.topConfirmBtnDeltVeh{
    display: flex;
    gap: 5px;

}

.noBtnAlertDltVeh{
    width: 100px;
    background-color: #03BABC;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    cursor: pointer;

}
.yesBtnAlertDltVeh{
    width: 100px;
    background-color: rgb(214, 45, 45);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;

}
.confirmTxtAlertVehDlt{
    color:  #03BABC;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;


}

@media (max-width:991px) {

    .mainDeleteCardVehicle{

        left: 35%;

    }
    
}
@media (max-width:700px) {

    .topTextsVehiclAdmin {
      width: unset;
    margin: auto;
    margin-top: 40px;
    gap: 50px;
    flex-direction: column;
    align-items: center;


    }
    .topTextEditAdmVehic{

        font-size: 25px;
        padding: 0px 10px;
    }

    .btnSearchVehicle {
        width: 75px;
    }
    .inputSearchVehic{
        width: 275px;
    }
    
}
@media (max-width:600px) {

    .mainDeleteCardVehicle{

        left: 25%;
        
    }
    
}
@media (max-width:430px) {

    .mainDeleteCardVehicle{

        left: 15%;
        
    }
    
}