.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.swal2-container {
  z-index: 999999 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mainDeleteCardVehicle1{
  width: auto !important;
  top: 10% !important;
  gap: 10px !important;
  position: fixed !important;


}
.confirmTxtAlertVehDlt1 p{
  font-size: 14px !important;

}

.noBtnAlertDltVeh1{
  width: 88px !important;
  height: 30px !important;
}
.lableTxt{
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
