.whtCrypHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 46.07px;
    line-height: 51px;
    
    color: #5FA618;

}

.whtIsCryMainTxt{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21.52px;
    line-height: 32px;
    text-align: center;
    margin-top: 23px;
    max-width: 1022px;
    color: #373737;
}

.mainContainerWhtIsCryp{
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    flex-direction: column;
    max-width: 70%;
    max-width: 1300px;
    margin: auto;
    padding-top: 106px;
    /* padding-bottom: 100px; */
}
.btnWhtIsCrypto{

    cursor: pointer;
 
    background: #fff;
    border-radius: 5.58625px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18.3676px;
    line-height: 28px;
    /* identical to box height */
    
    
    color: #5FA618;
    

letter-spacing: 1px;
display: flex;
justify-content: center;
align-items: center;
margin-top: 34px;

height: 44px;

}


.rightTxtHeadingWht{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 27.9312px;
    line-height: 42px;
    /* identical to box height */
    text-align: center;
    
    color: #fff;
    
/* identical to box height */
margin-top: 9px;

/* color: #1E2C36; */
}
.rightTxtParaWht{
 
margin-top: 6px;

text-align: center;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20.5837px;
line-height: 109.5%;
/* or 23px */

text-align: center;

color: #fff;
max-width: 340px;

}
.topCardRightWht{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 446.74px;
    height: 424.71px;
    background: 
    #5FA618;
box-shadow: 0px 0px 7.04921px rgba(0, 0, 0, 0.25);
border-radius: 5.28691px;


}
.bottomImgRight{
    display: flex;
    justify-content: center;
}
.topImgRight{
    display: flex;
    justify-content: center;
    margin-bottom: -66px;
}

.rightWhtCrypto{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 100%;
    margin-top: 62px;
}
.leftWhtCryp{
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    
    justify-content: center;
}
.topHeadingBottomWht{
    display: flex;
    justify-content: start;
    align-items: start;
    width: 90%;
    justify-content: center;
    margin-top: 10px;
    /* align-items: self-start; */
}
.topHeadingBottomWht img

{
    display: none;
}
@media (max-width:1280px) {
  .mainContainerWhtIsCryp{
    max-width: 1110px;
    
  }
    
  .whtCrypHeading{
    font-size: 31px;
    text-align: center;
  }
  .whtIsCryMainTxt{
    text-align: unset;
    padding: 0px 20px;
  }
  .leftWhtCryp{
    align-items: unset;
    /* padding-left: 30px; */
  }
  .whtIsCryMainTxt{
    text-align: center;
  }
  .topHeadingBottomWht{
    display: flex;
    justify-content: center;
    width: unset;
  }
}

@media (max-width:991px) {
    .rightWhtCrypto{
        flex-direction: column;
    }
    
}

@media (max-width:800px) {

    .mainContainerWhtIsCryp{
        flex-direction: column;
        align-items: center;
        gap: 50px;
        padding: 60px 30px;
    }
    .leftWhtCryp{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }
    .whtCrypHeading{
        text-align: center;
    }
    
}


@media (max-width:600px) {
    .whtIsCryMainTxt{
        font-size: 19px;
        padding: 0px 10px;
    }
    .whtCrypHeading {
        font-size: 22px;
    }
    .topCardRightWht {
        width: 320px;
    }
    .btnWhtIsCrypto{
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }
    .bottomImgRight img {
        width: 100px;
    }
    .topCardRightWht {
        height: 345px;
    }
    .rightTxtParaWht{
        padding: 0 10px;
    }
    #applyHere1{
        height: 50px;
    }
   
}