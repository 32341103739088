.mainHomTopBar{
    background-color: #313131;
    height: 35px;
    position: relative;
    width: 100%;
    display: flex;
    z-index: 9;
    justify-content: center;
    /* position: fixed; */
    top: 0px;
    align-items: center;
}
.txtmainHomeTp{
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;

}
.spTopBar{
  
    font-weight: 700;

}

.spTopBarEnd{
    border-bottom: 1px solid;
}
.crossTopBar{
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: -15px;
    /* top: 1%; */
    color: #fff;
}

@media (max-width:1200px) {
    .txtmainHomeTp {
        font-size: 12px;
    }
    
}

@media (max-width:991px) {

    .txtmainHomeTp{
        max-width: 600px;
        padding: 5px 5px;

    }
    .mainHomTopBar{
        height: auto;
    }

    
}
@media (max-width:600px) {

    .txtmainHomeTp{
        max-width: 350px;
      

    }

    .crossTopBar{
        font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    position: absolute;
    left: 5px;
    top: -5px;
    /* top: 1%;*/
    }


    
}