.mainFaq{
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding-top: 100px;
    /* margin-top: 20px; */
}

.bosyAccordTxt{
    font-weight: 400 !important;
    line-height: 26px;
    color: #8492a6;
    font-size: 16px;
    padding-left: 0px !important;
    font-family: 'Poppins', sans-serif;
}

.headerTxtAcc .accordion-button{
    font-weight: 600;
    font-size: 16px;
    color: #323c49;
    padding-left: 0px;
    line-height: 22px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    color: #2f55d4;
    line-height: 22px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 35.7059px;
    line-height: 69px;
    /* identical to box height */
    
    
    color: #282828;
}

.headerTxtAcc .collapsed{
    font-weight: 600;
    font-size: 16px;
    color: #323c49;
    line-height: 22px;
    font-style: normal;
    padding-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 35.7059px;
    line-height: 69px;
    /* identical to box height */
    
    
    color: #282828;


}

.accordion-button:focus{
    box-shadow: unset !important;

}

.accordion-button::after{

    background-image: url('./../../images/arrowDrop.png') !important;

}

.accordion-button:not(.collapsed)::after {

    background-image: url('./../../images/arrowDrop1.png') !important;
}

.mainFaqRightImg{
    width: 500px;
}

.accordion{
    width: 1660px;
    /* min-width: 1400px !important; */
    padding-left: 150px;
    padding-left: 8%;
    /* margin: auto; */
}
.accordion-item{
    margin-top: 5px;
    /* box-shadow: 0 0 3px rgba(60,72,88,.15); */
    /* border-top: 1px solid !important; */
}
.faqTxt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 46.07px;
    line-height: 69px;
    
    color: 
    #5FA618;
    

}
.topFaqLine img{
    display: none;
}
.topFaqLine{
    margin-bottom: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    padding-left: 150px;
    padding-left: 8%;
    margin: auto;
    margin-bottom: 30px;
    /* width: 84%; */
    

}
.mainAccordian{
    width: 100%;
    /* margin-top: 30px; */
}
.accordion-item{
    border: none !important;
}
@media (max-width:1676px) {
    .accordion{
        width: 1421px;
        padding-left: 4%;
        /* display: flex;
        justify-content: center; */
    }
    .topFaqLine{
      
        padding-left: 4%;
        
    
    }
    
}
@media (max-width:1436px) {
    .accordion{
        width: 1146px;
        margin: auto;
     
    }
    .topFaqLine{
      
        /* padding-left: 4%; */
        width: 1146px;
        margin: auto;
     
    
    }
    
    
}
@media (max-width:1162px) {
    .accordion{
        width: 980px;
        padding-left: 0px;
     
    }
    .topFaqLine{
      
        /* padding-left: 4%; */
        width: 980px;
   
        padding-left: 0px;
    
    }
    
}
@media (max-width:1186px) {
    .mainFaqRightImg{
        width: 400px;
    }
    .accordion {
        /* width: 515px; */
    }
    
}
@media (max-width:991px) {
    .mainFaq{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .accordion{
        width: 90%;
    }
    .topFaqLine{
      
        width: 90%;
    
    }
}
@media (max-width:600px) {
    .mainFaqRightImg{
        width: 100%;
        margin: auto;
    }
.topImgRightDiv{
    width: 80%;
}
    
.accordion{
    width: 95%;
}
.mainAccordian{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainFaqRightImg{
    display: none;
}
.topFaqLine{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faqTxt{
    font-size: 25px;
    text-align: center;
}
.headerTxtAcc .collapsed{
    font-size: 15px;
    line-height: 24px;
}
.headerTxtAcc .accordion-button{
    font-size: 15px;
    line-height: 24px;
}
}