.mainNewLLetter{
    height: 213px;
    width: 100%;
    background-color:  #329E2D;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.topMiddlNewsTxt{
    display: flex;
    flex-direction: column;
}

.subKepTxt{
    font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 29px;
line-height: 35px;
max-width: 295px;
color: #FFFFFF;
font-family: 'Barlow', sans-serif;
}


.newsLetterTxt{
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 46px;
line-height: 55px;
margin-top: 6px;
color: #FFFFFF;
font-family: 'Barlow', sans-serif;

}

.inputEmail{
    background: #FFFFFF;
border-radius: 44px;
padding-left: 25px;
width: 357px;
height: 56px;
font-family: 'Barlow', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 23px;
/* identical to box height */
border: none;

color: #666666;

}

.btnSubscribe{
    border: 2px solid #FFFFFF;
border-radius: 44px;
width: 151px;
height: 56px;
font-family: 'Barlow';
font-style: normal;
font-weight: 700;
font-size: 23px;
line-height: 28px;
/* identical to box height */

font-family: 'Barlow', sans-serif;
color: #FFFFFF;
display: flex;
justify-content: center;

align-items: center;
cursor: pointer;
}


.topInputBtn{
    display: flex;
    gap: 15px;
    width: 40%;
}
.topLeftNews{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-around;
    /* gap: 40%; */
}

.loaderNewLetter{

    font-size: 30px;
    animation: rotation 1s infinite linear;

}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@media (max-width:1150px) {


    .topInputBtn{

        width: 48%;
    }
}
@media (max-width:991px) {

    .mainNewLLetter{
        flex-direction: column;
        height: auto;
        padding: 50px 50px;
        gap: 30px;
    }
    .topInputBtn {
        width: 100%;
        justify-content: center;
    }
    .topLeftNews{
        width: 100%;
        justify-content: center;
        gap: 30px;
    }
}


@media (max-width:600px) {
    .topLeftNews{
        gap: 30px;
    }

    .subKepTxt{
        font-size: 22px;

        max-width: 194px;
    }
    .newsLetterTxt{
        font-size: 34px;
    }
    .topLeftNews img{
        width: 100px;
    }
    .mainNewLLetter {
       
        padding: 50px 5px;
      
    }
    .btnSubscribe {
      
        border-radius: 20px;
        width: 102px;
      
        font-size: 17px;
        
    }
.inputEmail {
   
   
    width: 259px;
  
    font-size: 15px;
   
}
}