.containerSideBar{
    display:flex;
  
    position: relative;
    /* z-index: 99; */


}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    background: #03BABC!important;
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
    position: relative;
    z-index: 99;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
    justify-content: space-between;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.bars svg{ 
    cursor: pointer;

}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:#4169e1;
    color: #fff;
    transition: all 0.5s;
}
.active{
    background:#4169e1;
    color: #fff;
}
.icon, .link_text{
    font-size: 20px;
}

.divToLevel{
    margin-top: 43px;
}
.offCanvasMobile{
    display: none;
}


@media (max-width:991px) {
    .containerSideBar{
        display: none;
    }
    .containerSideBarOffCan{
        display:flex;
      
        position: relative;
        /* z-index: 99; */
    
    
    }

    .offCanvasMobile{
        display: block;
        position: absolute;
        z-index: 999;
        width: 69px;
        left: 27px;
        top: 6px;
    
    

    }
    
    .offCanvasMobile .navbar{
        background-color: #ffff !important;
    

    }

    .offCanvasMobile nav .container-fluid{
        background-color: #fff !important;


    }
    .offCanvasMobile nav .container-fluid .navbar-toggler{
   /* padding: 0px !important; */
margin-top: 5px;

    }
    
}

@media (max-width:450px) {
    .containerSideBarOffCan div{
        width: 302px !important;
    }
    
    .offCanvasMobile {
       
        left: 3px;
        top: 11px;
    }
    .offCanvasAdminSidebar{
        width: 300px !important;
    }
    .offcanvas-body{
        padding: 0px !important;
    }
}