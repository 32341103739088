.mainHeaderComponent{
    /* height: 114vh; */
    width: 100%;
    background-image: url('./../../images/back1.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    /* padding-top: 54px; */
    height: auto;
    background-size: cover;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* padding-bottom: 170px; */
    align-items: center;
    height: 890px;
    background-size: 100% 100%;
}


.innerHeaderCompBack{

    /* height:80vh; */
    /* height: 700px;
    width: 100%; */
    /* background-image: url('./../../images/back2.png'); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    display: flex;
    position: relative;
    z-index: 999;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    /* padding-top: 80px; */

        /* display: none; */
    /* gap: 40px; */
    padding-top: 60px;
}

.imgBackSec{
    height: 76%;
    width: 89%;
    position: absolute;
    transition:all 10s linear;
    /* background-position: 0px 0px ,0px 0px; */
    /* animation:picSlider 10s linear infinite ; */
}
@keyframes picSlider {
    0%{
       
       
        /* background-position: 60px 0px,0px 0px; */
     
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(32deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
      
        perspective: 1000px;
        perspective-origin: 50% 50%;
        
    }
    20%{
       
       
        /* background-position: 60px 0px,0px 0px; */
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(-12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
      
        perspective: 1000px;
        perspective-origin: 50% 50%;
        
    }
    60%{
       
       
        /* background-position: 60px 0px,0px 0px; */
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(-42deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
      
        perspective: 1000px;
        perspective-origin: 50% 50%;
        
    }
    80%{
       
       
        /* background-position: 60px 0px,0px 0px; */
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-2deg) rotateY(8deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
      
        perspective: 1000px;
        perspective-origin: 50% 50%;
        
    }
    100%{
       
       
        /* background-position: 60px 0px,0px 0px; */
     
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(32deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
      
        perspective: 1000px;
        perspective-origin: 50% 50%;
        
    }
    /* 100%{
       
       
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-2deg) rotateY(32deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);

      
        perspective: 1000px;
        perspective-origin: 50% 50%;
        
    } */
   
}

.curatingTxt{

    font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 61.4198px;
line-height: 62px;
/* or 101% */

text-align: center;
font-family: 'Poppins', sans-serif;

color: #FFFFFF;
max-width: 583px;


}

.spnCurating{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 61.4198px;
    line-height: 62px;
    /* or 101% */
    
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    color: #03BABC;
    /* max-width: 483px; */

}

.txtHeaderComp{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20.05px;
    line-height: 144.5%;
    /* or 29px */
    
    text-align: center;
    letter-spacing: -0.045em;
    
    color: #464545;
    
font-family: 'Poppins', sans-serif;

max-width: 658px;
margin: auto;
margin-top: 27px;

}

.inestBtn{
    border: 2px solid #202020;
border-radius: 16px;
width: 176px;
height: 51px;
box-sizing: border-box;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 21.439px;
line-height: 32px;

color: #202020;

display: flex;
justify-content: center;
align-items: center;
font-family: 'Poppins', sans-serif;


}
.inestBtn:hover{
    background-color: #202020;
    color: #fff;
    border: 2px solid #252c4b;
}
.founderBtn{
    border: 2px solid #202020;
    border-radius: 16px;
width: 176px;
height: 51px;
box-sizing: border-box;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 21.439px;
line-height: 32px;

color: #202020;

display: flex;
justify-content: center;
align-items: center;
font-family: 'Poppins', sans-serif;


}
.founderBtn:hover{
    background-color: #202020;
    color: #fff;
    border: 2px solid #202020;

}
.areYouStak{
    border: 2px solid #FFFFFF;
    /* border-radius: 5px 0px 0px 5px; */
    width: 306px;
    height: 51px;

    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 21.439px;
line-height: 26px;
/* identical to box height */
cursor: pointer;

color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
gap: 12px;

}
.areYouStak1{
    background: #FFFFFF;
    /* border-radius: 0px 5px 5px 0px; */
    font-family: 'Barlow';
font-style: normal;
font-weight: 700;
font-size: 21.439px;
width: 306px;
height: 51px;

font-family: 'Poppins', sans-serif;
line-height: 26px;
/* identical to box height */
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
gap: 12px;
color: #00D2D4;


}

.rightArroHdCm{
    font-size: 33px;
}

.topButtonFounderInves{
    display: flex;
    margin-top: 50px;
    gap: 20px;
}

.inestTxt{
    font-family: 'Barlow';
font-style: normal;
font-weight: 700;
font-size: 40.29px;
line-height: 48px;
margin: auto;
color: #FFFFFF;
font-family: 'Poppins', sans-serif;

}

.investFutureTxt{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 24.5953px;
line-height: 30px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
cursor: pointer;

}

.regisInte{
    background: #00D2D4;
border-radius: 5px;

height: 55.04px;
width: 260px;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 20.8759px;
line-height: 25px;
/* identical to box height */


color: #FFFFFF;

display: flex;
justify-content: center;
align-items: center;
font-family: 'Poppins', sans-serif;
cursor: pointer;
margin-top: 20px;
}
.tabContectHdComp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
}

.Typewriter{
    font-style: normal;
    font-weight: 500;
    font-size: 61.4198px;
    line-height: 71px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #202020;
    max-width: 966px;
    margin: auto;

}

.Typewriter__cursor{
    /* display:none !important; */
}
.topTyeWriter .Typewriter .Typewriter__cursor{
    display: none;

}
@media (max-width:650px) {


.Typewriter {
   
    font-size: 32.4198px;
   
    max-width: 340px;
    line-height: 40px;
    margin: auto;
}
.txtHeaderComp {
   
    font-size: 15.05px;


    max-width: 303px;
    margin: auto;
    margin-top: 20px;
    /* margin-top: 10px; */
}
.topButtonFounderInves {
   
    flex-direction: column;
    gap: 19px;
    margin-top: 39px;
}
.areYouStak {
    width: 229px;
    height: 40px;
    font-size: 16px;
}
.areYouStak1 {
    width: 229px;
    height: 40px;
    font-size: 16px;
}
.rightArroHdCm {
    font-size: 23px;
}
.mainHeaderComponent{
    padding-top: 21px;
}
.inestTxt {
    font-size: 29px;
}
.investFutureTxt {
    font-size: 20px;
}
.regisInte{
    height: 40px;
    width: 219px;
    font-size: 16px;
}
.mainHeaderComponent{
    /* height: 720px; */
}
.innerHeaderCompBack{
    height: 80vh;
    padding-top: 18px;
}

.mainHeaderComponent {
    

}
}