.mainComingSoon{
    height: 90vh;
    width: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comingSoonTxt{
    font-size: 80px;
color: #5FA618;
}

@media (max-width:600px) {
    .comingSoonTxt {
        font-size: 50px;
    }
    
}