.addVehicleDrawer {
    width: 50% !important;
  }
  
  .topCatagoryField {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputFieldAddVeh {
    width: 90%;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    box-shadow: none;
    color: #3c4858;
    font-size: 13px;
    min-height: 42px !important;
    transition: all 0.5s ease;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;

  }
  
  .catagoryTxtAddVeh {
    font-size: 14px;
    font-weight: 700;
    color: #161c2d;
    font-family: 'Nunito,sans-serif';
    font-family: 'Poppins', sans-serif;
    width: 100px;
  }
  
  .switch1 {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch1 input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider1 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider1:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider1 {
    background-color: #2196f3;
  }
  
  input:focus + .slider1 {
    box-shadow: 0 0 1px #2196f3;
  }
  
  input:checked + .slider1:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider1.round1 {
    border-radius: 34px;
  }
  
  .slider1.round1:before {
    border-radius: 50%;
  }
  
  .topCatagoryFieldCheck {
    justify-content: unset;
    gap: 10px;
  }
  
  .mainAddVehicleDrawerFields {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .cnclBtn {
    width: 100px;
    border: none;
    border: 1px solid red;
    color: red;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    height: 40px;
    padding: 10px 10px;
    border-radius: 2px;
    font-size: 20px;
  }
  
  .addBtn {
    width: 100px !important;
    border: none;
    /* border:1px solid red; */
    color: #fff;
    background-color:rgba(11,21,41,1);
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 2px;
    height: 40px !important;
    font-family: 'Poppins', sans-serif;
    /* border: 1px solid red; */
    padding: 11px 20px;
  }
  
  .selectCustomeizeDrivers {
    height: 300px;
    min-width: 200px;
    width: auto;
    position: absolute;
    top: -310px;
    left: 0px;
  
    z-index: 9999;
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
    border: 1px solid #d6d6d6;
    box-shadow: 0px 8px 12px -6px rgb(0 0 0 / 30%);
    background-color: #ffffff;
    /* padding: 5px 6px 5px 5px; */
  }
  
  .inputFieldAddVeh {
  
    width: 100%;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    box-shadow: none;
    color: #3c4858;
    font-size: 13px;
    /* height: 42px; */
    transition: all 0.5s ease;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
  
  }
  .inputFieldAddVehTopSlc{
    position: relative;
    width: 90%;
    /* display: flex;
    align-items: center; */
  }
  
  .drinverTxtInd{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 10px;
    cursor: pointer;
    border-bottom: 1px solid lightgray;
  
  
  }
  
  .drinverTxtInd:hover{
    color: #fff;
    background-color: rgb(42, 157, 244);
  
  }
  .inputFieldAddVehDrivers{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .addBlogDrawerAd div{

    padding: 0px !important;
  }
  .addBlogDrawerAd{
    background: none !important;
    background-color: none !important;
    height: 30px !important;
    margin-top: 10px !important;
    /* margin-bottom: 0px !important; */
  }
  .react-file-reader-button .btn{
    padding: 0px !important;
  }
  .addVehicleDrawer{
    z-index: 99999 !important;
  }

  .topCatagoryFieldCheck{
    margin-top: 20px;
  }

  .spVerify{
    background-color: black;
    color: #fff;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
  }
  @media (max-width: 991px) {
    .addVehicleDrawer {
      width: 100% !important;
    }
  }
  