@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#navbarScroll {
    /* display: flex !important;
    justify-content: space-between;

    gap: 20px; */
}

.navbar{
    padding: 0px !important;
    background-color: #fff !important;
    box-shadow: 0 0 3px rgb(60 72 88 / 15%);
    height: 90px !important;
    position: relative;
    transition:all 10s linear;
    z-index: 9999;
}
.me-auto{
    margin-right: unset !important;
}
.navbar.active{
    position: fixed;
    transition: all .5s ease;
z-index: 999;
    top: 0;
}
.logoImg{
 
    
}
.fixed-top {
    top: 35px !important;
}

.nav-link{

    color: #fff !important;
    font-size: 14.29px !important; 

    font-weight: 600 !important;
    letter-spacing: 1.5px !important;
    line-height: 24px !important;

    font-family: 'Barlow', sans-serif;

    transition: all .5s ease;



}
.btnTippies{
    color: #fff !important;
    font-size: 14.29px !important; 

    font-weight: 600 !important;
    letter-spacing: 1.5px !important;
    line-height: 24px !important;

    font-family: 'Barlow', sans-serif;

    transition: all .5s ease;
    background: none;
    border: none;

}

.btnTippies:hover{
    color: #5FA618!important;  
}
.loginNavApply{
    width: 109.07px;
height: 35px;
gap: 10px;
display: flex !important;
justify-content: center;
align-items: center;
background: #5FA618;
border: 1.72619px solid #5FA618;
border-radius: 5px;
font-family: 'Open Sans', sans-serif;

}
.rightArrowApply{
    font-size: 22px;

}
.nav-link:hover{

    color: #03BABC!important;  


}
.mainContentTippy{
    display: flex;
    gap: 8px;
    flex-direction: column;
}
.mainContentTippy span{
    font-size: 14px;
    padding: 10px 40px 5px 10px;
    border-radius: 5px !important;
    font-family: 'Barlow', sans-serif;
    letter-spacing: .5px;
    font-weight: 400;
    color: #222 !important;
}
.mainContentTippy span:hover{
  /* background-color: #fff !important; */
  color: #5FA618 !important;
}
.tippy-content{
    padding: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.loginNavApply:hover{
    color: #000000 !important;


}

.container-fluid{
    background-color: #000000 !important;
    padding-top: 10px;
    transition:all 10s linear;
    padding-bottom: 10px;
}


.loginNav{
    border-radius: 2px;
    background-color:black!important;

    color: #fff!important;

    display: flex !important;
    align-items: center;
    font-weight: 600 !important;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Barlow', sans-serif;
    gap: 3px;
}

.loginIconNav{
    font-size: 18px;
    font-family: 'Barlow', sans-serif;
}

.loginNav:hover{


    color: #5FA618!important;


}

.loginNav1{
    border-radius: 2px;
    background-color:black!important;
    border: 1px solid #fff;
    color: #fff!important;

    display: flex !important;
    align-items: center;
    font-weight: 600 !important;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    gap: 3px;
}
.loginNav1:hover{
    background-color: white!important;

    color: black!important;
}

.topLogSign{
    display: flex !important;
    align-items: center;
    gap: 8px;
    justify-content: end !important;
    padding-right: 63px;
    margin-right: 0px !important;
    width: 33%;
}

.navbarBrand{
    display: none;
}

a{
    color: unset !important;
    text-decoration: none !important;
}

.threeLine{
    font-size: 30px;
}

.firstNav{
    justify-content: start !important;
    padding-left: 63px !important;
    width: 33%;
    display: flex !important;
}
.spanColored{
    font-weight: bold !important;
}
.topLogoNav {
    width: 33%;
    display: flex !important;
    justify-content: center;


}

.firstNav  .active{
    background: unset !important;
}
.tippy-content{
    width: 100% !important;
}
.tippy-content div{
    width: 100% !important;
}
.tippy-box{
    background-color: #fff !important;
    color: #252c4b  !important;
    box-shadow: 0 0 18px rgb(0 0 0 / 25%) !important;


}
.tippy-arrow{
    background-color: #fff !important;
    color: #fff !important;



}
#tippy-18{
    box-shadow: 0 0 18px rgb(0 0 0 / 25%) !important;
}
.linkClass{
    font-size: 18px;
    padding: 5px 40px 5px 10px;
    border-radius: 5px !important;
    font-family: 'Barlow', sans-serif;
    letter-spacing: .5px;
}
@media (max-width:1146px) {


    
}


@media (max-width:1056px) {
    
    
}

@media (max-width:991px) {
    #navbarScroll{
        /* justify-content: unset !important; */
        /* display: unset !important; */
    }
    #navbarScroll {
        /* display: unset !important;
        justify-content: unset !important;
    
        gap: unset !important; */
    }
    .tippy-box[data-placement^=bottom]>.tippy-arrow:before{
        left: 50% !important;
    }
    .tippy-arrow{
    
        background-color: unset !important;
    }
    .mainContentTippy span {
        /* color: #fff !important; */
    }

    .firstNav{
        padding-left: unset !important;
        height: auto !important;
        max-height: unset !important;
    }

    .topLogSign{
        max-height: unset !important;
        width: unset !important;
        align-items: unset !important;
        padding-right: unset !important;
        justify-content: unset !important;
    }
    .loginNavApply{
        margin: auto !important;
    }

    .navbar{
        height: unset !important;
    }
    .topLogoNav{
        display: none !important;
    }
    .fixed-top {
        top: 60px !important;
    }
    .navbarBrand{
        display: block;
    }
    .logoImgHide{
        display: none;
    }
    .nav-link{
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    #navbarScroll div{
        width: 100%;
    }
    .loginNav1{
        padding: 10px !important;
     }
    .loginNav{
        padding: 10px !important;
    }
    .navbar-toggler{
      
    }
    .container-fluid{
        padding: 0px 10px !important;
    }
    .navbar-toggler:focus{
        box-shadow: unset !important;
    }
}

@media (max-width:600px) {
    .fixed-top {
        top: 110px !important;
    }
    .mainHeaderComp{
        padding-top: 100px;
    }

    .logoImg{
        height: 65px;
    }
    
}