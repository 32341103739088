.addBlogDrawerAd .container-fluid{

    width: 100px !important;
    display: flex;
    justify-content: end;
    align-items: end;
}

.editColDataVeh{
    display: flex !important;
}
.editColDataVeh .addBlogDrawerAd{
    width: 100px;
}
