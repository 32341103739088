.topBarMain{
    width: 100%;
    padding: 11px 0px;
    /* height: 65px; */
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topBarMainImg{
    /* width: 182px; */
    /* object-fit: cover; */

}